import { render, staticRenderFns } from "./SocialMediaTemp.vue?vue&type=template&id=a926e272&scoped=true"
import script from "./SocialMediaTemp.vue?vue&type=script&lang=js"
export * from "./SocialMediaTemp.vue?vue&type=script&lang=js"
import style0 from "./SocialMediaTemp.vue?vue&type=style&index=0&id=a926e272&prod&lang=sass&scoped=true"
import style1 from "./SocialMediaTemp.vue?vue&type=style&index=1&id=a926e272&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a926e272",
  null
  
)

export default component.exports